<template>
  <div>
    Login with Xumm
  </div>
</template>

<script>

export default {

}
</script>
